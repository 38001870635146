import { userCountryLink } from 'config'

export enum Country {
  GB = 'GB',
  AU = 'AU',
  CA = 'CA',
  US = 'US',
  JP = 'JP',
  HK = 'HK',
}

export enum Continent {
  EU = 'EU',
  OC = 'OC',
  NA = 'NA',
  AS = 'AS',
}

type LocationError = 'location no data'

export type LocationData =
  | {
      city: string
      continent: Continent | string
      country: Country
      region: string
      timezone: string
    }
  | LocationError

const setLocalStorage = (data: LocationData) => {
  localStorage.setItem('hint-location', JSON.stringify(data))
}

const getUserIP = async () => {
  await fetch(`${userCountryLink}`, { method: 'GET', mode: 'cors' })
    .then((response) => {
      if (!response.ok) {
        setLocalStorage('location no data')
      }
      return response.json()
    })
    .then((data) => setLocalStorage(data))
}

export default getUserIP
