import ArrowLeftIcon from 'assets/svg/ArrowLeftIcon'
import clsx from 'clsx'
import { FC, HTMLAttributes } from 'react'
import { useNavigationRoutes } from 'utils/hooks'

import styles from './styles.module.scss'

type Props = HTMLAttributes<HTMLDivElement> & {
  isBackHistory?: boolean
}

const ButtonBack: FC<Props> = ({ isBackHistory = false, children, className, ...props }) => {
  const { navigateBack } = useNavigationRoutes()

  return (
    <div onClick={navigateBack} className={clsx(styles.buttonWrapper, className)} {...props}>
      {children ?? <ArrowLeftIcon />}
    </div>
  )
}

export default ButtonBack
