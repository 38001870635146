/**
 * JS Variable Naming Convention for Experiment Variants
 *
 * - For binary experiment variants (0 or 1):
 *   Use the format: [First letters of experiment name in upper case]IsOn
 *   Example: For an experiment named 'Color Change', use 'CCIsOn'.
 *
 * - For multi-option experiment variants:
 *   Use the format: [First letters of experiment name in upper case][Variant]Variant
 *   Example: For an experiment named 'LayoutUpdate', use 'LUFirst for the first specific variant.'
 */

/* eslint-disable react-hooks/rules-of-hooks */
import { useFeature } from '@growthbook/growthbook-react'
import { useLocation } from 'react-router-dom'

export enum Experiments {
  ProgressBarLoaders = 'palmistry_progress_bar_loaders',
  PriceNY = 'palmistry_price_ny',
  PreUploadLoader = 'palmistry_pre_upload_loader',
  HalfMinPrice = 'palmistry_min_price_half_dollar',
  PersonalStatement = 'palmistry_personal_statement',
  PalmistryPrimer = 'palmistry_primer',

  PalmistryNewFunnel = 'palmistry_new_funnel_v2',
  // old experiments that were not tested
  BirthdayWheel = 'palmistry_birthday_wheel',
  GenderOptions = 'palmistry_gender_options',
  PalmWelcome = 'palmistry_welcome',
}

export enum ExperimentVariant {
  disabled,
  first,
  second,
  third,
}

type Props = {
  key: string
  skip?: boolean
}

const useGrowthbookFeatureVariation = ({ key, skip }: Props): ExperimentVariant => {
  if (skip) return ExperimentVariant.disabled
  const { search } = useLocation()
  const variationFromParam = Number(new URLSearchParams(search).get(key))
  const feature = useFeature(key)

  if (variationFromParam) return variationFromParam

  return feature?.experimentResult?.variationId || 0
}

export default useGrowthbookFeatureVariation
