import { MouseEvent, RefObject, useEffect, useState } from 'react'

const useMouseDrag = (ref: RefObject<HTMLDivElement | null>) => {
  const [isMouseDown, setIsMouseDown] = useState<boolean>(false)
  const [mouseStartY, setMouseStartY] = useState<number>(0)
  const [scrollStartY, setScrollStartY] = useState<number>(0)

  const onMouseDown = (event: MouseEvent<HTMLDivElement>) => {
    setIsMouseDown(true)
    setMouseStartY(event.clientY)
    setScrollStartY(event.currentTarget?.scrollTop || 0)
  }

  // eslint-disable-next-line
  const onMouseMove = (event: MouseEvent) => {
    if (!isMouseDown) return
    const deltaY = event.clientY - mouseStartY
    const newScrollTop = scrollStartY - deltaY
    if (ref.current) {
      ref.current.scrollTop = newScrollTop
    }
  }

  const onMouseUp = () => {
    setIsMouseDown(false)
  }

  useEffect(() => {
    const handleMouseMove = (event: MouseEvent) => {
      onMouseMove(event)
    }

    const handleMouseUp = () => {
      onMouseUp()
    }

    if (isMouseDown) {
      // @ts-ignore
      window.addEventListener('mousemove', handleMouseMove)
      window.addEventListener('mouseup', handleMouseUp)
    }

    return () => {
      // @ts-ignore
      window.removeEventListener('mousemove', handleMouseMove)
      window.removeEventListener('mouseup', handleMouseUp)
    }
  }, [isMouseDown, onMouseMove, ref])

  return { onMouseDown, onMouseMove, onMouseUp, isMouseDown }
}

export default useMouseDrag
