import { isAstrologyHost } from 'config/flags'

export const hexToRGBA = (hex: string, alpha: number) => {
  const r = parseInt(hex.slice(1, 3), 16)
  const g = parseInt(hex.slice(3, 5), 16)
  const b = parseInt(hex.slice(5, 7), 16)
  return `rgba(${r}, ${g}, ${b}, ${alpha})`
}

const BaseColors = {
  '--pale-lavender': '#dee5f9',
  '--slate-blue': '#6B7BAA',
  '--slate-blue-svg': '#6B7BAA',
  '--gentle-blue': '#9babd9',
  '--svg-blue': '#066fde',
  '--svg-blue-to-gold': '#066fde',
  '--pale-light-cerulean': '#ACD1FF',
  '--svg-deep-midnight-blue': '#066fde',
  '--white-to-transparent': '#ffffff',
  '--svg-gradient-accent': '#BCC9ED',
  '--svg-gradient-light': '#dee5f9',
  '--footer-shield': '#B5C4FF',
  '--gentle-blue-svg': '#9babd9',
  '--pale-sky-blue': '#C1E1FE',
  '--gentle-cerulean': '#57A0EC',
  '--vivid-amber': '#F5B000',
  '--rich-cobalt': '#005BBB',
  '--svg-bright-white': '#FBFBFB',
  '--svg-palm-color': '#005BBB',
  '--svg-palm-color-accent': '#FF5758',
  '--svg-palm-color-sub': '#FFCDCD',
  '--svg-palm-color-trans': '#FFCDCD',
  '--svg-logo-color': '#6B7BAA',
  '--svg-black-to-pale-blue': '#000000',
  '--svg-black-to-gold': '#121620',
  '--svg-white-to-dusky-gray': '#FFFFFF',
  '--svg-hint-logo-glow': '#0',
  '--svg-hint-logo-color': 'url(#gradient)',
  '--svg-couple-logo-switch': false,
}

const ThemeColors = {
  '--slate-blue': '#F1A564',
  '--slate-blue-svg': '#C5B2BF',
  '--gentle-blue': '#F1F3FF',
  '--svg-blue': '#ffffff',
  '--svg-blue-to-gold': '#F1A564',
  '--pale-light-cerulean': '#F1F3FF',
  '--svg-deep-midnight-blue': '#0F1045',
  '--white-to-transparent': '#0',
  '--svg-gradient-accent': 'url(#gradient_svg)',
  '--svg-gradient-light': '#ffffff',
  '--footer-shield': '#C5B2BF',
  '--gentle-blue-svg': '#C5B2BF',
  '--pale-sky-blue': '#FDDAA6',
  '--gentle-cerulean': '#DEB475',
  '--vivid-amber': '#F1A564',
  '--rich-cobalt': '#4A3D61',
  '--svg-bright-white': 'url(#gradient_svg)',
  '--svg-palm-color': '#939699',
  '--svg-palm-color-accent': '#AD5AFF',
  '--svg-palm-color-sub': '#AD5AFF',
  '--svg-palm-color-trans': '#0',
  '--svg-logo-color': '#A6B0D2',
  '--svg-black-to-pale-blue': '#F1F3FF',
  '--svg-black-to-gold': 'url(#gradient_svg)',
  '--svg-white-to-dusky-gray': '#706180',
  '--svg-hint-logo-glow': 'url(#glow)',
  '--svg-hint-logo-color': '#ffffff',
  '--svg-couple-logo-switch': true,
}

type ColorsType = typeof BaseColors & typeof ThemeColors

const COLORS: ColorsType = {
  ...BaseColors,
  ...(isAstrologyHost ? ThemeColors : {}),
}

export default COLORS
