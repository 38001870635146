import { Language, Onboarding } from 'types'

import { supportedLanguages } from '../translations/languages'

export const createBoards = (): { [key in Onboarding]: Onboarding } => {
  const boards: { [key in Onboarding]?: Onboarding } = {}

  supportedLanguages.forEach((lang) => {
    const key = `palmistry-${lang}` as `palmistry-${Language}`
    boards[key] = key
  })

  return boards as { [key in Onboarding]: Onboarding }
}

export const boards = createBoards()
