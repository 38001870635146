import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

export const useQueryParams = () => {
  const [queryParams, setQueryParams] = useState<string | null>(null)

  const location = useLocation()

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const newParams = []
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of params.entries()) {
      newParams.push([key, value])
    }
    const paramsString = newParams?.map(([key, value]) => `${key}=${value}`).join('&')
    setQueryParams(paramsString ?? null)
    // eslint-disable-next-line
  }, [])

  return queryParams
}
