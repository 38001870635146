import { supportedLanguages } from '../translations/languages'
import { Language, Onboarding } from '../types'

const palmistryVersion = '0.1'

export const createFunnelVersions = (): { [key in Onboarding]: string } => {
  const funnelVersions: { [key in Onboarding]?: string } = {}

  supportedLanguages.forEach((lang) => {
    const key = `palmistry-${lang}` as `palmistry-${Language}`
    funnelVersions[key] = palmistryVersion
  })

  return funnelVersions as { [key in Onboarding]: string }
}

const funnelVersions = createFunnelVersions()
export default funnelVersions
