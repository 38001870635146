import Logo from 'assets/logos/Logo'
import { ReactComponent as AstrologyIcon } from 'assets/svg/astrology-logo.svg'
import { FC, memo } from 'react'

import { isAstrologyHost } from '../../config/flags'
import ButtonBack from './button-back'
import styles from './styles.module.scss'

const Header: FC = () => {
  return (
    <header className={styles.header}>
      <ButtonBack />
      {isAstrologyHost ? <AstrologyIcon /> : <Logo />}
    </header>
  )
}

export default memo(Header)
