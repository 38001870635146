import { useGrowthBook } from '@growthbook/growthbook-react'
import { useCallback } from 'react'
import { useStore } from 'store/useStore'
import { sha256 } from 'utils'

const useInitGtag = () => {
  const changeGtagClientId = useStore((state) => state.changeGtagClientId)
  const growthbook = useGrowthBook()
  const { gtag, fbq } = window

  const init = useCallback(() => {
    gtag('get', 'G-S35JE8DVYJ', 'client_id', (clientId: string) => {
      changeGtagClientId(clientId)

      growthbook?.setAttributes({
        id: clientId,
      })

      sha256(clientId).then((token) => {
        fbq('init', `${process.env.REACT_APP_FB_PIXEL_API_KEY}`, { external_id: token })
        fbq('track', 'PageView')
      })
    })
  }, [changeGtagClientId, fbq, gtag, growthbook])

  return init
}

export default useInitGtag
