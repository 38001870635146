import { RefObject, useCallback, useState } from 'react'

interface WheelPickerFunctions {
  onScrollWheel: () => void
  forceUpdateScroll: (unit: number) => void
  centerIndex: number
}

const useWheelPicker = (
  degStep: number,
  lineHeight: number,
  cloneScrollerRef: RefObject<HTMLDivElement>,
  pickerScrollerRef: RefObject<HTMLDivElement>,
): WheelPickerFunctions => {
  const [centerIndex, setCenterIndex] = useState<number>(0)

  // eslint-disable-next-line
  const updateDisplay = (options: HTMLCollectionOf<HTMLElement>, centerIndex: number) => {
    const optionsNo = options.length

    for (let i = 0; i < optionsNo; i++) {
      if (options[i]) {
        options[i].style.display = i === centerIndex ? 'block' : 'none'
      }
    }

    for (let i = centerIndex; i < centerIndex + 7; i++) {
      if (options[i]) {
        options[i].style.display = 'block'
      }
    }

    if (centerIndex > 7) {
      for (let i = centerIndex; i >= centerIndex - 7; i--) {
        if (options[i]) {
          options[i].style.display = 'block'
        }
      }
    } else {
      for (let i = 0; i < 8; i++) {
        if (options[i]) {
          options[i].style.display = 'block'
        }
      }
    }
  }

  const forceUpdateScroll = useCallback((unit: number) => {
    const scroller = pickerScrollerRef.current
    // eslint-disable-next-line
    const options = scroller?.children as HTMLCollectionOf<HTMLElement>
    const newCenterIndex = Math.round(unit / degStep)

    if (scroller) {
      scroller.style.transform = `translateZ(-105px) rotateX(${unit}deg)`
    }

    if (options[newCenterIndex]) {
      setCenterIndex(newCenterIndex)
    }

    updateDisplay(options, newCenterIndex)
    // eslint-disable-next-line
  }, [])

  const onScrollWheel = useCallback(() => {
    const cloneScroller = cloneScrollerRef.current
    const cloneScrollTop = cloneScroller?.scrollTop || 0
    const optionsNo = pickerScrollerRef.current?.children.length || 0
    const cloneHeight = lineHeight * optionsNo
    const totalDeg = degStep * optionsNo
    const unit = Math.round(((totalDeg / cloneHeight) * cloneScrollTop) / degStep) * degStep

    forceUpdateScroll(unit)
    // eslint-disable-next-line
  }, [lineHeight, cloneScrollerRef, pickerScrollerRef, forceUpdateScroll])

  return {
    centerIndex,
    onScrollWheel,
    forceUpdateScroll,
  }
}

export default useWheelPicker
