import clsx from 'clsx'
import { FC, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import styles from './styles.module.scss'

type Props = {
  validScreens: string[]
}

const ProgressBar: FC<Props> = ({ validScreens }) => {
  const [isHidden, setIsHidden] = useState(false)
  const [index, setIndex] = useState(1)
  const [progress, setProgress] = useState(0)
  const location = useLocation()

  const arrayPathName = location.pathname.split('/')
  const currentPath = `${arrayPathName[2]}`
  const currentIndex = validScreens.indexOf(currentPath)
  useEffect(() => {
    if (validScreens.includes(currentPath)) {
      const totalScreens = validScreens.length
      const newProgress = ((currentIndex + 1) / totalScreens) * 100
      setIndex(currentIndex + 1)
      setProgress(newProgress)
    }
  }, [currentPath, currentIndex, validScreens])

  useEffect(() => {
    if (currentIndex === -1) {
      setIsHidden(true)
    }
    if (currentIndex !== -1 && isHidden) {
      setIsHidden(false)
    }
  }, [currentIndex, isHidden])

  return (
    <div className={clsx(styles.progressBarWrapper, isHidden && styles.hidden)}>
      <p className={styles.currentRoute}>{`${index}/${validScreens.length}`}</p>
      <div className={clsx(styles.progressBar, index === validScreens.length && styles.progressBarFinish)}>
        <div className={styles.progress} style={{ width: `${progress}%` }} />
      </div>
    </div>
  )
}

export default ProgressBar
