import { PlanCodeData } from 'types'

export * from './links'
export * from './boards'

export const subscriptionPlans: PlanCodeData[] = [
  // { planCode: '1_1_week_1900_100', count: 100 },
  { planCode: '1_1_week_1900_052', count: 52 },
  { planCode: '1_1_week_1900_500', count: 500 },
  { planCode: '1_1_week_1900_900', count: 900 },
  { planCode: '1_1_week_1900_1321', count: 1321 },
]

export const subscriptionPlansWithHolidayPricing: PlanCodeData[] = [
  { planCode: '1_1_week_2300_100', count: 100 },
  { planCode: '1_1_week_2300_500', count: 500 },
  { planCode: '1_1_week_2300_900', count: 900 },
  { planCode: '1_1_week_2300_1321', count: 1321 },
]

export const subscriptionHalfDollarPlan: PlanCodeData = { planCode: '1_1_week_1900_50', count: 50 }
