import { ReactNode } from 'react'

export type Value = number | string
export type Option = {
  value: Value
  displayValue: ReactNode
}

export enum RelationshipOptions {
  SINGLE = '1',
  IN_RELATIONSHIP = '2',
}

export enum WishOptions {
  LOVE = '1',
  HEALTH = '2',
  CAREER = '3',
}

export enum GenderOptions {
  MALE = '1',
  FEMALE = '2',
  NON_BINARY = '3',
}

export type ResonatedElementOptions = 'air' | 'water' | 'fire' | 'earth'
export type ColorYouLikeOptions = 'red' | 'yellow' | 'blue' | 'orange' | 'green' | 'violet'
export enum DecisionOptions {
  'heart' = 'heart',
  'head' = 'head',
  'both' = 'both',
}
export type BirthdayType = {
  year: Option | null
  month: Option | null
  day: Option | null
}
export type BornTime = {
  hours: number | null
  minutes: number | null
  meridiem: 'AM' | 'PM' | null
}

export type Zodiac =
  | 'aries'
  | 'taurus'
  | 'gemini'
  | 'cancer'
  | 'leo'
  | 'virgo'
  | 'libra'
  | 'scorpio'
  | 'sagittarius'
  | 'capricorn'
  | 'aquarius'
  | 'pisces'

export type TarotMessagesActivityTime = {
  messageTime: string
  answerTime: string
}
