import { lazy } from 'react'
import { RouteObject } from 'react-router-dom'

import { paths } from './paths'

const Welcome = lazy(() => import('pages/palm-welcome'))
const Gender = lazy(() => import('pages/gender'))
const Birthday = lazy(() => import('pages/birthday'))
const PalmsHold = lazy(() => import('pages/palms-hold'))
const Wish = lazy(() => import('pages/wish'))
const RelationshipStatus = lazy(() => import('pages/relationship-status'))
const ResonatedElement = lazy(() => import('pages/resonated-element'))
const ColorYouLike = lazy(() => import('pages/color-you-like'))
const Decisions = lazy(() => import('pages/decisions'))
const GuidancePlan = lazy(() => import('pages/guidance-plan'))
const PalmScanInfo = lazy(() => import('pages/palm-scan-info'))
const PalmUpload = lazy(() => import('pages/palm-upload'))
const ScanPhoto = lazy(() => import('pages/scan-photo'))
const SelectPlan = lazy(() => import('pages/selected-plan'))
const Email = lazy(() => import('pages/palm-email-form'))
const Paywall = lazy(() => import('pages/paywall'))
const ExperimentPersonalStatementPage = lazy(() => import('pages/experiment-personal-statement'))

export const routes: Array<RouteObject> = [
  {
    path: paths.palmWelcome,
    element: <Welcome />,
  },
  {
    path: paths.gender,
    element: <Gender />,
  },
  {
    path: paths.birthday,
    element: <Birthday />,
  },
  {
    path: paths.palmsHold,
    element: <PalmsHold />,
  },
  {
    path: paths.wish,
    element: <Wish />,
  },
  {
    path: paths.relationshipStatus,
    element: <RelationshipStatus />,
  },
  {
    path: paths.resonatedElement,
    element: <ResonatedElement />,
  },
  {
    path: paths.colorYouLike,
    element: <ColorYouLike />,
  },
  {
    path: paths.decisions,
    element: <Decisions />,
  },
  {
    path: paths.guidancePlan,
    element: <GuidancePlan />,
  },
  {
    path: paths.personalStatement,
    element: <ExperimentPersonalStatementPage />,
  },
  {
    path: paths.palmScanInfo,
    element: <PalmScanInfo />,
  },
  {
    path: paths.palmUpload,
    element: <PalmUpload />,
  },
  {
    path: paths.scanPhoto,
    element: <ScanPhoto />,
  },
  {
    path: paths.palmEmail,
    element: <Email />,
  },
  {
    path: paths.subscriptionPlan,
    element: <SelectPlan />,
  },
  {
    path: paths.paywall,
    element: <Paywall />,
  },
]
