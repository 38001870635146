import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import { listOfTranslations, supportedLanguages } from './languages'

export const DEFAULT_LANG = 'en'

export const DEFAULT_VARIABLES = {
  hint: 'Hint',
}

const params = new URLSearchParams(document.location.search)
const languageFromParams = params.get('language')
const languageFromLS = localStorage.getItem('lng')

if (languageFromParams) {
  localStorage.setItem('lng', languageFromParams)
}

i18n.use(initReactI18next).init({
  supportedLngs: supportedLanguages,
  returnObjects: true,
  fallbackLng: DEFAULT_LANG,
  lng: languageFromParams || languageFromLS || DEFAULT_LANG,
  resources: {
    ...listOfTranslations,
  },
  interpolation: {
    defaultVariables: {
      ...DEFAULT_VARIABLES,
      escapeValue: false,
    },
  },
  react: {
    transWrapTextNodes: '',
    transSupportBasicHtmlNodes: true,
    transKeepBasicHtmlNodesFor: ['br', 'em', 's', 'b', 'a', 'span'],
  },
  debug: false,
  returnEmptyString: false,
})

export default i18n
