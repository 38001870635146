import { useTranslation } from 'react-i18next'
import { useGrowthbookFeatureVariation } from 'utils/hooks'

import {
  // baseOldPurchaseUrl,
  basePurchaseUrl,
} from '../../config'
import { useStore } from '../../store/useStore'
import { BirthdayType, BornTime, WishOptions } from '../../types'
import { ExperimentVariant, Experiments } from './useGrowthbookFeatureVariation'

enum ParamKeys {
  Gender = 'gender',
  RelationshipStatus = 'relationshipStatus',
  Interest = 'interest',
  Email = 'email',
  PlanCode = 'planCode',
  Funnel = 'funnel',
  BirthDateTime = 'bDT',
  PalmId = 'palmId',
  TempId = 'tId',
  KnownBirthTime = 'kBT',
  Language = 'language',
  GtagClientId = 'gCId',
}

const toDateString = (birthday: BirthdayType | null | undefined, bornTime: BornTime | null | undefined) => {
  if (!birthday?.year || !birthday?.month || !birthday?.day) return null

  const {
    year: { value: yearValue },
    month: { value: monthValue },
    day: { value: dayValue },
  } = birthday

  let result = `${dayValue}.${monthValue}.${yearValue}`

  if (typeof bornTime?.hours === 'number' && typeof bornTime?.minutes === 'number') {
    const hours = bornTime.meridiem === 'PM' ? 12 + bornTime.hours : bornTime.hours
    const formatHours = hours < 10 ? `0${hours}` : hours
    const formatMinutes = bornTime.minutes < 10 ? `0${bornTime.minutes}` : bornTime.minutes

    result = `${result} ${formatHours}:${formatMinutes}`
  } else {
    result = `${result} 00:00`
  }

  return result
}

const useRedirectToPurchase = () => {
  const { i18n } = useTranslation()
  const emailUser = useStore((state) => state.emailUser)
  const planCodeData = useStore((state) => state.planCodeData)
  const birthday = useStore((state) => state.birthday)
  const funnel = useStore((state) => state.funnel)
  const photoData = useStore((state) => state.photoData)
  const gtagClientId = useStore((state) => state.gtagClientId)
  const gender = useStore((state) => state.gender)
  const relationship = useStore((state) => state.relationship)
  const wish = useStore((state) => state.wish)

  // TODO: remove these temporary params after test
  const PNYVariant = useGrowthbookFeatureVariation({ key: Experiments.PriceNY })
  // const PPVariant = useGrowthbookFeatureVariation({ key: Experiments.PalmistryPrimer })

  return () => {
    const params = {
      [ParamKeys.Gender]: gender,
      ...(wish === WishOptions.LOVE && { [ParamKeys.RelationshipStatus]: relationship }),
      [ParamKeys.Interest]: wish,
      [ParamKeys.Email]: emailUser,
      [ParamKeys.PlanCode]: planCodeData.planCode,
      [ParamKeys.Funnel]: funnel,
      [ParamKeys.BirthDateTime]: toDateString(birthday, null),
      [ParamKeys.PalmId]: photoData?.palm_id,
      [ParamKeys.KnownBirthTime]: false,
      [ParamKeys.GtagClientId]: gtagClientId,
      [ParamKeys.TempId]: process.env.REACT_APP_TEMP_ID,
      [ParamKeys.Language]: i18n.resolvedLanguage,
      ...(PNYVariant === ExperimentVariant.first && {
        [Experiments.PriceNY]: PNYVariant,
      }),
    }

    // window.location.href = `${PPVariant === ExperimentVariant.first ? baseOldPurchaseUrl : basePurchaseUrl}?${(
    //   Object.keys(params) as ParamKeys[]
    // )
    window.location.href = `${basePurchaseUrl}?${(Object.keys(params) as ParamKeys[])
      .filter((key) => params[key])
      .map((key) => `${key}=${params[key]}`)
      .join('&')}`
  }
}

export default useRedirectToPurchase
