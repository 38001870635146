import { AnimatePresence } from 'framer-motion'
import { FC, ReactNode } from 'react'

import styles from './styles.module.scss'

type Props = {
  children: ReactNode
}

const AnimatePage: FC<Props> = ({ children }) => {
  return (
    <div className={styles.animateWrapper}>
      <AnimatePresence initial={false}>{children}</AnimatePresence>
    </div>
  )
}

export default AnimatePage
