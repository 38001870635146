const zeroFormatString = (string: string) => {
  const isZero = /^[0]/.test(string)

  if (isZero && string.length >= 3) {
    return string.slice(1)
  }

  if (isZero && +string > 0) {
    return `0${string[string.length - 1]}`
  }

  if (string.length <= 1 && !!string) {
    return `0${string}`
  }
  return string
}

export default zeroFormatString
