import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'

import { Actions, State } from './types'

const initialState: State = {
  funnel: 'palmistry-en',
  routes: [],
  relationship: null,
  gender: null,
  name: '',
  birthday: { year: null, month: null, day: null },
  photoData: null,
  emailUser: '',
  gtagClientId: null,
  planCodeData: { planCode: '1_1_week_1900_1321', count: 1321 },
  wish: null,
  resonatedElement: null,
  colorYouLike: null,
  decision: null,
}

export const useStore = create<State & Actions>()(
  persist(
    devtools(
      immer((set) => ({
        ...initialState,
        changeFunnel: (funnel) => set({ funnel }),
        changeRoutes: (routes) => set({ routes }),
        changeGender: (gender) => set({ gender }),
        changeName: (name) => set({ name }),
        changeBirthday: (birthday) => set({ birthday }),
        changeWish: (wish) => set({ wish }),
        changeRelationship: (relationship) => set({ relationship }),
        setPhotoData: (photoData) => set({ photoData }),
        changeEmailUser: (emailUser) => set({ emailUser }),
        changePlanCode: (planCodeData) => set({ planCodeData }),
        changeGtagClientId: (gtagClientId) => set({ gtagClientId }),
        changeResonatedElement: (resonatedElement) => set({ resonatedElement }),
        changeColorYouLike: (colorYouLike) => set({ colorYouLike }),
        changeDecision: (decision) => set({ decision }),
        reset: () => set(initialState),
      })),
    ),
    {
      name: 'hint-state',
      migrate: (persistedState: State & Actions) => {
        return persistedState
      },
    },
  ),
)
