import { useCallback, useEffect, useMemo, useState } from 'react'

interface Time {
  minutes: number
  seconds: number
}

const useTimer = (timerLSName: string, lastStartTimestampLSName: string) => {
  const initialTime: Time = useMemo(() => ({ minutes: 15, seconds: 0 }), [])
  const [time, setTime] = useState<Time>(initialTime)
  const [isTimerEnded, setIsTimerEnded] = useState(false)

  const formatTime = useCallback((part: number): string => {
    return part < 10 ? `0${part}` : part.toString()
  }, [])

  useEffect(() => {
    const storedTime = localStorage.getItem(timerLSName)
    if (storedTime) {
      const parsedTime = JSON.parse(storedTime)
      const prevTimestamp = localStorage.getItem(lastStartTimestampLSName)
      if (prevTimestamp) {
        const elapsedTime = Math.floor((Date.now() - parseInt(prevTimestamp, 10)) / 60000)
        if (elapsedTime >= 15 || (parsedTime.minutes === 0 && parsedTime.seconds === 0)) {
          setTime(initialTime)
          localStorage.setItem('timer', JSON.stringify(initialTime))
        } else {
          setTime(parsedTime)
        }
      } else {
        setTime(parsedTime)
      }
    }

    localStorage.setItem(lastStartTimestampLSName, Date.now().toString())
  }, [initialTime, timerLSName, lastStartTimestampLSName])

  useEffect(() => {
    const interval = setInterval(() => {
      setTime((prevTime) => {
        if (prevTime.seconds === 0) {
          if (prevTime.minutes === 0) {
            setIsTimerEnded(true)
            clearInterval(interval)
            return prevTime
          }
          return { minutes: prevTime.minutes - 1, seconds: 59 }
        }
        return { ...prevTime, seconds: prevTime.seconds - 1 }
      })
    }, 1000)

    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    localStorage.setItem(timerLSName, JSON.stringify(time))
  }, [time, timerLSName])

  return {
    time,
    formatTime,
    isTimerEnded,
  }
}

export default useTimer
