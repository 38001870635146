import { Variants, motion } from 'framer-motion'
import { FC, ReactNode } from 'react'

type Props = {
  children: ReactNode
}

const size = {
  width: '100%',
  height: 'fit-content',
  maxWidth: 560,
}

const animateVariants: Variants = {
  initial: {
    opacity: 0,
    x: '100vw',
    position: 'absolute',
    transition: { delay: 0.1, duration: 0.25 },
    ...size,
  },
  animate: {
    position: 'initial',
    opacity: 1,
    x: 0,
    transition: { delay: 0.1, duration: 0.25 },
    ...size,
  },
  exit: {
    opacity: 0,
    x: '-100vw',
    transition: { duration: 0.25 },
    ...size,
  },
}

const PageAnimateWrapper: FC<Props> = ({ children }) => {
  return (
    <motion.div className="motionDiv" variants={animateVariants} initial="initial" animate="animate" exit="exit">
      {children}
    </motion.div>
  )
}

export default PageAnimateWrapper
