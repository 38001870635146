import { useLocation } from 'react-router-dom'
import { useStore } from 'store/useStore'

const useCurrentRouteIndex = () => {
  const routes = useStore((state) => state.routes)
  const location = useLocation()
  const arrayPathName = location.pathname.split('/')

  const currentPath = `${arrayPathName[2]}`

  const currentIndexRoute = routes.findIndex((route) => route === currentPath)

  return currentIndexRoute
}
export default useCurrentRouteIndex
