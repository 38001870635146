import { isLocalhost } from 'config/flags'
import { FC, Suspense, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate, Route, RouteObject, Routes, useLocation } from 'react-router-dom'
import { useStore } from 'store/useStore'
import { Onboarding } from 'types'
import { getUserIP } from 'utils'
import { useGrowthbookFeatureVariation, useInitGtag } from 'utils/hooks'

import AnimatePage from 'components/animate-page'
import PageAnimateWrapper from 'components/animate-page/page-wrapper'
import Header from 'components/header'
import PageWrapper from 'components/page-wrapper'
import ProgressBar from 'components/progress-bar'

import { ExperimentVariant, Experiments } from '../../utils/hooks/useGrowthbookFeatureVariation'
import { paths } from './paths'
import { routes } from './routes'

export const palmistryPaths = routes.map((item) => item.path) as Array<string>

type Props = {
  skipPages?: string[]
}
const validScreensForProgressBar = [
  paths.gender,
  paths.birthday,
  paths.palmsHold,
  paths.wish,
  paths.relationshipStatus,
  paths.resonatedElement,
  paths.colorYouLike,
  paths.decisions,
  paths.guidancePlan,
]

const PalmistryOnboarding: FC<Props> = ({ skipPages }) => {
  const { i18n } = useTranslation()
  const location = useLocation()

  const initGtag = useInitGtag()

  const changeRoutes = useStore((state) => state.changeRoutes)
  const changeFunnel = useStore((state) => state.changeFunnel)

  const filterOutSkippedPages = useCallback(
    <T extends string | RouteObject>(array: T[]): T[] => {
      return array.filter((el) => {
        const path = typeof el === 'string' ? el : el.path || ''
        return !skipPages?.includes(path)
      })
    },
    [skipPages],
  )

  useEffect(() => {
    getUserIP()
    if (!isLocalhost) {
      initGtag()
    }
  }, [initGtag])

  useEffect(() => {
    if (skipPages?.length) {
      changeRoutes(filterOutSkippedPages(palmistryPaths))
    } else {
      changeRoutes(palmistryPaths)
    }
    // localStorage.setItem('lng', i18n.resolvedLanguage)
  }, [changeRoutes, filterOutSkippedPages, skipPages])

  useEffect(() => {
    changeFunnel(`palmistry-${i18n.resolvedLanguage}` as Onboarding)
  }, [i18n, changeFunnel])

  useEffect(() => {
    setTimeout(() => window.scrollTo(0, 0), 100)
  }, [location.pathname])

  const PS = useGrowthbookFeatureVariation({ key: Experiments.PersonalStatement })
  const PSIsOn = PS === ExperimentVariant.first
  useEffect(() => {
    if (PSIsOn) {
      validScreensForProgressBar.push(paths.personalStatement)
    }
  }, [PSIsOn])

  return (
    <PageWrapper>
      <Suspense>
        <Header />
        <ProgressBar validScreens={filterOutSkippedPages(validScreensForProgressBar)} />
      </Suspense>
      <AnimatePage>
        <Routes location={location} key={location.pathname}>
          {filterOutSkippedPages(routes).map((route) => (
            <Route
              key={route.path}
              path={route.path}
              element={
                <PageAnimateWrapper>
                  <Suspense>{route.element}</Suspense>
                </PageAnimateWrapper>
              }
            />
          ))}
          <Route path="/*" element={<Navigate to={`${paths.palmWelcome}${location.search}`} replace />} />
        </Routes>
      </AnimatePage>
    </PageWrapper>
  )
}

export default PalmistryOnboarding
