import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate, Route, Routes } from 'react-router-dom'

import { newPalmistryUrl } from '../config'
import { isAstrologyHost } from '../config/flags'
import { useGrowthbookFeatureVariation } from '../utils/hooks'
import { ExperimentVariant, Experiments } from '../utils/hooks/useGrowthbookFeatureVariation'
import { useQueryParams } from '../utils/hooks/useQueryParams'
import PalmistryOnboarding from './palmistry-onboarding'
import { paths as palmistryPaths } from './palmistry-onboarding/paths'

const Onboardings = () => {
  const { i18n } = useTranslation()
  const queryParams = useQueryParams()

  const PSVariant = useGrowthbookFeatureVariation({ key: Experiments.PersonalStatement })
  const PSIsOn = PSVariant === ExperimentVariant.first
  const skipPages = PSIsOn ? undefined : [palmistryPaths.personalStatement]

  const PSFVariant = useGrowthbookFeatureVariation({ key: Experiments.PalmistryNewFunnel, skip: isAstrologyHost })
  const PSFIsOn = PSFVariant === ExperimentVariant.first

  useEffect(() => {
    if (PSFIsOn) {
      window.location.replace(`${newPalmistryUrl}/${i18n.resolvedLanguage}/?${queryParams}`)
    }
  }, [PSFIsOn, queryParams, i18n.resolvedLanguage])

  return (
    <Routes>
      <Route path="/palmistry/*" element={<PalmistryOnboarding skipPages={skipPages} />} />
      <Route path="/palmistry2/*" element={<PalmistryOnboarding skipPages={[palmistryPaths.birthday]} />} />
      <Route path="/*" element={<Navigate to="/palmistry/*" replace />} />
    </Routes>
  )
}

export default Onboardings
