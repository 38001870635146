export const paths = {
  palmWelcome: 'welcome',
  relationshipStatus: 'relationship-status',
  gender: 'gender',
  birthday: 'birthday',
  palmsHold: 'palms-hold',
  resonatedElement: 'resonated-element',
  colorYouLike: 'color-you-like',
  decisions: 'decisions',
  guidancePlan: 'guidance-plan',
  personalStatement: 'personal-statement',
  palmScanInfo: 'scan-info',
  palmUpload: 'upload',
  palmEmail: 'email',
  paywall: 'paywall',
  subscriptionPlan: 'subscription-plan',
  wish: 'wish',
  scanPhoto: 'scan-photo',
}
