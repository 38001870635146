import { useCurrentRouteIndex } from 'utils/hooks'

import ButtonBack from 'components/button-back'

import styles from './styles.module.scss'

const HeaderButtonBack = () => {
  const currentRouteIndex = useCurrentRouteIndex()

  if (!currentRouteIndex) {
    return null
  }

  return <ButtonBack className={styles.buttonPosition} />
}

export default HeaderButtonBack
