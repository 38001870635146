/* eslint-disable no-console */
import { boards } from 'config'
import { isDev, isLocalhost } from 'config/flags'
import { Onboarding } from 'types'
import { funnelVersions, getFunnelFromLocalStorage } from 'utils'

type Arg = {
  name: string
  gtagData?: { [key: string]: unknown } | [] | string
}

type AnalyticEvent = (arg: Arg) => void

export enum EventNames {
  OnboardingStarted = 'PalmistryOnboardingStarted',
  IntroClicked = 'PalmistryOnboardingIntroClicked',
  GenderClicked = 'PalmistryOnboardingGenderClicked',
  DobClicked = 'PalmistryOnboardingDOBClicked',
  InfoScreenClicked = 'PalmistryOnboardingInfoScreenClicked',
  WishClicked = 'PalmistryOnboardingWishClicked',
  RelStatusClicked = 'PalmistryOnboardingRelStatusClicked',
  ElementChosen = 'PalmistryOnboardingElementChosen',
  ColorChosen = 'PalmistryOnboardingColorChosen',
  DecisionPathChosen = 'PalmistryOnboardingDecisionPathChosen',
  GuidanceClicked = 'PalmistryOnboardingGuidanceClicked',
  ScanIntroClicked = 'PalmistryOnboardingScanIntroClicked',
  ScanIntroEntered = 'PalmistryOnboardingScanIntroEntered',
  PhotoUploadOptionClicked = 'PalmistryOnboardingPhotoUploadOptionClicked',

  ScannerPassed = 'PalmistryOnboardingScannerPassed',

  EmailClicked = 'PalmistryOnboardingEmailClicked',
  PlanClicked = 'PalmistryOnboardingPlanPicked',
  HelpClicked = 'PalmistryOnboardingHelpClicked',
  ExitToCheckout = 'PalmistryOnboardingExitToCheckout',

  OnboardingError = 'PalmistryOnboardingError',
  OnboardingScanErrorShown = 'PalmistryOnboardingScanErrorShown',
}

export const createEventTypes = (): { [key: string]: string } => {
  const eventTypes: { [key: string]: string } = {}
  Object.keys(boards).forEach((key) => {
    eventTypes[key] = key.startsWith('palmistry') ? 'PalmistryOnboarding' : 'TarotOnboarding'
  })
  return eventTypes
}

const eventTypes = createEventTypes()

const trackGTagAnalytic: AnalyticEvent = ({ name, gtagData = '' }) => {
  const funnel = getFunnelFromLocalStorage()
  if (Array.isArray(gtagData) || typeof gtagData === 'string') {
    gtagData = { value: gtagData }
  }

  const params = {
    event_type: eventTypes[funnel as Onboarding],
    event_category: '<WebOnboarding>',
    event_label: funnel,
    version: funnelVersions[funnel as Onboarding],
    ...gtagData,
  }

  try {
    window.gtag('event', name, params)
  } catch (error: unknown) {
    if (isDev) console.error(`Failed to sent gtag event ${name}`, error)
  }
}

const analyticEvent: AnalyticEvent = ({ name, gtagData = '' }) => {
  if (!isLocalhost) {
    trackGTagAnalytic({ name, gtagData })
  }
}

export default analyticEvent
