import dayjs, { Dayjs } from 'dayjs'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import { Zodiac } from 'types'

dayjs.extend(isSameOrBefore)
dayjs.extend(isSameOrAfter)

const useZodiac = (date: Dayjs) => {
  const currentYear = date.year()

  const zodiacRanges: Array<{ zodiac: Zodiac; start: string; end: string }> = [
    { zodiac: 'aries', start: '03-21', end: '04-19' },
    { zodiac: 'taurus', start: '04-20', end: '05-20' },
    { zodiac: 'gemini', start: '05-21', end: '06-20' },
    { zodiac: 'cancer', start: '06-21', end: '07-22' },
    { zodiac: 'leo', start: '07-23', end: '08-22' },
    { zodiac: 'virgo', start: '08-23', end: '09-22' },
    { zodiac: 'libra', start: '09-23', end: '10-22' },
    { zodiac: 'scorpio', start: '10-23', end: '11-21' },
    { zodiac: 'sagittarius', start: '11-21', end: '12-21' },
    { zodiac: 'capricorn', start: '12-22', end: '01-19' },
    { zodiac: 'aquarius', start: '01-20', end: '02-18' },
    { zodiac: 'pisces', start: '02-19', end: '03-20' },
  ]

  const getZodiac = zodiacRanges.find((zodiac) => {
    return (
      date.isSameOrAfter(`${currentYear}-${zodiac.start}`, 'month') &&
      date.isSameOrBefore(`${currentYear}-${zodiac.end}`, 'month')
    )
  })

  return getZodiac?.zodiac || 'aries'
}
export default useZodiac
