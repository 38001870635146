export const isLocalhost = window.location.href.includes('localhost')

export const isDev = process.env.REACT_APP_ENVIRONMENT === 'development'

export const isAstrologyHost =
  window.location.host.includes('astrology') || window.location.search.includes('astrology_theme')

export const isMobile =
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(
    navigator.userAgent,
  )
