import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useStore } from 'store/useStore'
import { useCurrentRouteIndex } from 'utils/hooks'

import { useQueryParams } from './useQueryParams'

const useNavigationRoutes = () => {
  const queryParams = useQueryParams()
  const navigate = useNavigate()
  const currentIndexRoute = useCurrentRouteIndex()

  const routes: string[] = useStore((state) => state.routes)

  const canNavigateNext = currentIndexRoute + 1 < routes.length
  const canNavigateBack = currentIndexRoute - 1 >= 0

  const nextRoute = routes[currentIndexRoute + 1]
  // const previousRoute = routes[currentIndexRoute - 1]

  const navigateNext = useCallback(
    (to?: string) => {
      if (to && routes?.includes(to)) {
        navigate(`../${to}?${queryParams}`)
      } else if (canNavigateNext && nextRoute) {
        navigate(`../${nextRoute}?${queryParams}`)
      }
    },
    [navigate, routes, canNavigateNext, nextRoute, queryParams], // обновленный список зависимостей
  )

  const navigateBack = useCallback(() => {
    if (canNavigateBack) {
      navigate(-1)
    }
  }, [canNavigateBack, navigate])

  return {
    navigateNext,
    navigateBack,
    canNavigateNext,
    canNavigateBack,
  }
}

export default useNavigationRoutes
