import * as ar from './dictionaries/ar.json'
import * as da from './dictionaries/da.json'
import * as de from './dictionaries/de.json'
import * as en from './dictionaries/en.json'
import * as es from './dictionaries/es.json'
import * as fr from './dictionaries/fr.json'
import * as hu from './dictionaries/hu.json'
import * as it from './dictionaries/it.json'
import * as ja from './dictionaries/ja.json'
import * as ko from './dictionaries/ko.json'
import * as nb from './dictionaries/nb.json'
import * as nl from './dictionaries/nl.json'
import * as pl from './dictionaries/pl.json'
import * as pt from './dictionaries/pt.json'
import * as ro from './dictionaries/ro.json'
import * as sv from './dictionaries/sv.json'
import * as tr from './dictionaries/tr.json'

export const supportedLanguages = [
  'ar',
  'de',
  'en',
  'es',
  'fr',
  'it',
  'ja',
  'ko',
  'nl',
  'pt',
  'ro',
  'tr',
  'da',
  'hu',
  'nb',
  'pl',
  'sv',
] as const

export const listOfTranslations = {
  ar: {
    translation: ar,
  },
  da: {
    translation: da,
  },
  de: {
    translation: de,
  },
  en: {
    translation: en,
  },
  es: {
    translation: es,
  },
  fr: {
    translation: fr,
  },
  hu: {
    translation: hu,
  },
  it: {
    translation: it,
  },
  ja: {
    translation: ja,
  },
  ko: {
    translation: ko,
  },
  nb: {
    translation: nb,
  },
  nl: {
    translation: nl,
  },
  pl: {
    translation: pl,
  },
  pt: {
    translation: pt,
  },
  ro: {
    translation: ro,
  },
  sv: {
    translation: sv,
  },
  tr: {
    translation: tr,
  },
}
