import { Continent, Country, LocationData } from '../getUserIP'

enum Currency {
  USD = 'USD',
  EUR = 'EUR',
  GBP = 'GBP',
  AUD = 'AUD',
  CAD = 'CAD',
}

const currencyData = [
  { cents: 50, label: '$0.50' },
  { cents: 51, label: '$0.51' },
  { cents: 52, label: '$0.52' },
  { cents: 100, label: '$1.00' },
  { cents: 500, label: '$5.00' },
  { cents: 900, label: '$9.00' },
  { cents: 1321, label: '$13.21' },
]

const currencyMultiplier = {
  [Currency.USD]: 1,
  [Currency.EUR]: 1,
  [Currency.GBP]: 1,
  [Currency.AUD]: 1.5,
  [Currency.CAD]: 1.3,
}

const currencyValue = Object.fromEntries(
  Object.entries(Currency).map(([code]) => [
    code,
    Object.fromEntries(currencyData.map((item) => [item.label, item.cents * currencyMultiplier[code as Currency]])),
  ]),
)

const useGetUserCurrency = () => {
  const getUserCurrency = () => {
    const storedData = localStorage.getItem('hint-location')

    if (!storedData) return Currency.USD

    const locationData: LocationData = JSON.parse(storedData)

    if (typeof locationData === 'string') {
      return Currency.USD
    }

    switch (locationData.continent) {
      case Continent.EU:
        return locationData.country === Country.GB ? Currency.GBP : Currency.EUR
      case Continent.NA:
        return locationData.country === Country.CA ? Currency.CAD : Currency.USD
      case Continent.OC:
        return Currency.AUD
      default:
        return Currency.USD
    }
  }

  const transformCurrency = ({
    cents,
    currency = Currency.USD,
    minimumFractionDigits,
  }: {
    cents: number
    currency?: Currency
    minimumFractionDigits?: number
  }) => {
    const isWholeNumber = cents % 1 === 0

    const returnedValue = (cents / 100).toLocaleString('en-US', {
      style: 'currency',
      currency,
      minimumFractionDigits: minimumFractionDigits ?? (isWholeNumber ? 0 : 2),
      maximumFractionDigits: 2,
    })

    if (currency === Currency.CAD) {
      return returnedValue.replace('CA$', 'C$')
    }

    return returnedValue
  }

  const toLocalCurrency = (cents: number, minimumFractionDigits?: number): string => {
    const userCurrency = getUserCurrency()
    const currencyValues = currencyValue[userCurrency]
    const label = currencyData.find((item) => item.cents === cents)?.label

    if (label && currencyValues[label] !== undefined) {
      return transformCurrency({
        cents: currencyValues[label],
        currency: userCurrency,
        minimumFractionDigits,
      })
    }

    return 'Error: Invalid currency value'
  }

  return { toLocalCurrency }
}

export default useGetUserCurrency
